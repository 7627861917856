import * as Cookies from "js-cookie";

export class CookieConsent
{
	private readonly cookieName = ".AspNet.Consent";
	private readonly storageKey = "cookieConsentActioned";
	private readonly cookieConsentValue: string;
	private readonly cookieBanner: HTMLElement;

	constructor()
	{
		this.cookieBanner = document.querySelector("#cookieConsent") as HTMLElement;

		if (!this.cookieBanner)
			return;

		this.cookieConsentValue = this.cookieBanner.dataset.cookieString ?? "";

		const actioned = localStorage.getItem(this.storageKey);

		if (actioned === "true")
		{
			// If we get here it means that the banner was rendered, but the fact they have actioned before means they declined them.
			// Just hide the banner
			this.cookieBanner.style.display = "none";
		}
		else
		{
			// The banner has been rendered but the user has never actioned before
			// This is the only time the banner will be displayed. The user can use their account settings to enable cookies at other times.
			const acceptButton = this.cookieBanner.querySelector(".accept-policy") as HTMLButtonElement;
			const declineButton = this.cookieBanner.querySelector(".decline-policy") as HTMLButtonElement;

			if (!acceptButton || !declineButton)
				return;

			acceptButton.addEventListener("click", this.toggleCookies.bind(this, true), false);
			declineButton.addEventListener("click", this.toggleCookies.bind(this, false), false);
		}
	}

	public toggleCookies(enable: boolean): void
	{
		localStorage.setItem(this.storageKey, "true");

		if (enable)
		{
			if (this.cookieConsentValue)
				document.cookie = this.cookieConsentValue;

			this.cookieBanner.style.display = "none";
		}
		else
		{
			Cookies.remove(this.cookieName);
		}
	}

	public areCookiesEnabled(): boolean
	{
		return !!Cookies.get(this.cookieName);
	}
}