import "jquery";
import "bootstrap";
import "../styles/index.scss";
import { BlazorInterop } from "./blazor";
import { CookieConsent } from "./components/cookie-consent";

declare const APP_ENVIRONMENT: "Development" | "Production";

(() =>
{
	console.log(`Temp365 Dental - Loading - ${APP_ENVIRONMENT}`);

	const cookieConsent = new CookieConsent();

	// eslint-disable-next-line
	// @ts-ignore
	window.BlazorInterop = new BlazorInterop(cookieConsent);
})();